<template>
    <div>
        <div class="page-title">API接口</div>

        <div>
            <pay-box title="接口信息">
                <table class="at-table addr-table">
                    <tr>
                        <th>API Key</th>
                        <td>{{ openInfo.app_key }}</td>
                    </tr>
                    <tr>
                        <th>API Secret</th>
                        <td>
                            <span>{{ openInfo.app_secret }}</span>
                            <a @click="resetAppSecret" class="ml-2">重置</a>
                        </td>
                    </tr>
                    <tr>
                        <th>事件接收配置</th>
                        <td>
                            <div class="flex flex-left-center">
                                <span class="mr-2">{{ openInfo.notify_url }}</span>
                                <modal-edit title="事件接收URL" @save="urlChanged">
                                    <a>配置</a>
                                </modal-edit>
                            </div>
                        </td>
                    </tr>
                </table>
            </pay-box>
            <pay-box title="接口文档">
                <a href="https://documenter.getpostman.com/view/14245403/2sAXxQeXVB" target="_blank">
                    https://documenter.getpostman.com/view/14245403/2sAXxQeXVB
                </a>
            </pay-box>
        </div>
    </div>
</template>

<script>
import PayBox from "@/views/AST/Pay/Step/PayBox.vue";
import api from "@/repo/api";
import ModalEdit from "@/components/ModalEdit.vue";

export default {
    name: "Api",
    components: {ModalEdit, PayBox},
    data() {
        return {
            openInfo: {}
        }
    },
    mounted() {
        this.getApiInfo()
    },
    methods: {
        getApiInfo() {
            api.get('/ast-app/open-info', res => {
                this.openInfo = res.data
            })
        },
        resetAppSecret() {
            this.$confirm({
                title: "确定要重置APP Secret？",
                onOk: () => {
                    this.$loading.show()

                    api.post('/ast-app/refresh-app-secret', {}, res => {

                        this.$loading.hide()

                        this.$message.data(res)

                        if (res.code === 0) this.openInfo = res.data

                    })
                }
            })
        },
        urlChanged(url) {

            if (url && url.indexOf('http://') !== 0 && url.indexOf('https://')) {

                this.$message.error('url地址填写错误');

                return false
            }

            this.$loading.show()

            api.post('/ast-app/change-notify-url',{url},res => {

                this.$loading.hide()
                this.$message.data(res)

                if (res.code === 0) this.openInfo = res.data
            })


        }
    }
}
</script>

<style scoped lang="less">
.addr-table {
    width: 100%;

    td {
        text-align: left;
    }
}
</style>